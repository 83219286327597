<template>
  <div class="buttonsContainer">
    <div v-for="button in block.buttons" :key="button.id" :class="`size-${block.size ?? 'auto'}`">
      <ExternalButton :block="block" :button="button"/>
    </div>
  </div>
</template>


<script>
import ExternalButton from "./ExternalButton.vue";

export default {
  components: {
    ExternalButton
  },
  props: {
    block:{
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      touchDown: false,
    };
  },
  computed: {

  },
  methods: {
  },
  mounted() {
    //
    console.log('size',this.block.size)
  },
};
</script>

<style scoped lang="scss">
.buttonsContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1rem 2rem;
  gap: .5rem;

  & > div {
    
    max-width: 60vw;
  }

  .size- {
    &auto {
      flex: 1;
    }
    &small {
      min-width: 17%;
    }
    &medium {
      min-width: 30%;
    }
    &big {
      min-width: 40%;
    }
  }
}


</style>
